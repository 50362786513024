#error404 {
  .error404Body {
    padding: 2rem;
    display: grid;
    justify-content: center;
    gap: 1.5rem;
    text-align: center;
    .error404Info {
      .error404InfoTitle {
        margin-bottom: 0.75rem;
      }
      .error404InfoDescription {
        margin-bottom: 2.5rem;
      }
    }
  }
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}
